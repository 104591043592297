<template>
<!-- Show Loading Before Redirect to register -->
  <div class="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
    Loading...
  </div>


  <div
    v-if="false"
    class="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8"
  >
    <div class="w-full max-w-sm space-y-10">
      <div>
        <img
          class="mx-auto h-10 w-auto"
          src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
          alt="Your Company"
        />
        <h2
          class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900"
        >
          Sign in to your account
        </h2>
      </div>

      <div
        v-if="!isSendedOtp"
        class="relative -space-y-px rounded-md shadow-sm"
      >
        <div
          class="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300"
        />
        <div>
          <label for="email-address" class="sr-only">Phone</label>
          <input
            id="email-address"
            name="email"
            type="text"
            v-model="phoneNumber"
            required=""
            class="text-sm pl-3 relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Phone number"
          />
        </div>
      </div>

      <AuthOTPInput v-if="isSendedOtp" v-model="otpInput" />

      <div>
        <button
          @click="clickSignInWithPhoneNumber"
          type="submit"
          class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <div
            v-if="isLoading"
            :disabled="isLoading"
            class="mr-2 animate-spin inline-block w-5 h-5 border-[3px] border-current border-t-transparent text-white rounded-full"
          ></div>
          {{btnLabel}}
        </button>

        <AppDivider text="Sign Up With" class="my-3" />
        <button          
          @click="onShowMainModal(true)"          
          class="mt-1 flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-gray-800 ring-1 ring-inset ring-gray-500"
        >
          Register
        </button>
        <button          
          @click="onShowMainModal(true)"          
          class="mt-1 flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-gray-800 ring-1 ring-inset ring-gray-500"
        >
          Line
        </button>

        <button
          v-if="isSendedOtp"
          @click="back"
          type="submit"
          class="mt-1 flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-gray-800 ring-1 ring-inset ring-gray-500"
        >
          Back
        </button>
      </div>    
    </div>
    <div id="recaptcha-container"></div>
  </div>
</template>

<script setup>
import { useAuthStore } from "@/stores/auth";
import { useAppStateStore} from "@/stores/appState"
import liff from '@line/liff';

definePageMeta({
  layout: "login",
  freeAuth: true,
});

const appStateStore = useAppStateStore();
const authStore = useAuthStore();
const router = useRouter();

const phoneNumber = ref("0909636552");
const isSendedOtp = ref(false);
const isLoading = ref(false)
const otpInput = ref("");
const btnLabel = computed(()=> isSendedOtp.value ? "Confirm OTP" : "Sign In")
const idRecaptchaDiv = "recaptcha-container";

onMounted(async() => {
  //router.push("/register")
  onShowMainModal(true)
  //await initPhoneNumberAuth()
});
const initPhoneNumberAuth = async()=>{
    await authStore.clearAuth();
  setTimeout(() => {
    authStore.initRecaptchaVerifer(idRecaptchaDiv);
  }, 250);
}

const onShowMainModal = (status)=>{
    appStateStore.setShowMainModal(status)
}

const clickSignInWithPhoneNumber = async () => {
  try {
    if (isSendedOtp.value == false) {
      isLoading.value = true
      await sendOtp();
      isLoading.value = false
      return;
    }
    if (!otpInput.value) {
      appStateStore.useToast({    
        text: 'กรุณาใส่รหัส otp ก่อนทำการเข้าสู่ระบบ', 
        type: 'error'
      })    
      return  
    }
    isLoading.value = true
    await authStore.phoneLogin(otpInput.value);
    const resUser = await authStore.fetchAuth();

    if(!resUser){
      isLoading.value = false
      appStateStore.useToast({    
        text: 'ไม่พบ Event ที่ลงทะเบียนไว้', 
        type: 'error'
      })   
      return
    }
    isLoading.value = false
    localStorage.setItem("loginType", "phoneNumber")
    // window.location.href="/main"
    window.location.href="/v2/home"
  } catch (error) { 
    console.error("error=>", error.message);
    if (error.message.includes("auth/invalid-verification-code")) {
      appStateStore.useToast({    
        text: 'กรอก otp ไม่ถูกต้อง', 
        type: 'error'
      })   
      isLoading.value = false 
      return  
    }
  }
};

const sendOtp = async () => {
  isSendedOtp.value = false;
  await authStore.sendOtp(phoneNumber.value);
  appStateStore.useToast({    
    text: 'ส่ง OTP ไปยังเบอร์โทรศัพท์เรียบร้อย', 
    type: 'success'
  })
  isSendedOtp.value = true;
  return;
};

const back = ()=>{
  isSendedOtp.value = false
  otpInput.value = ""
}
</script>
